/* eslint-disable max-len */
import { SEO } from 'components/blocks/seo';
import { Advantages } from 'components/shared/alternative-advantages';
import { AlternativeFeedbacks } from 'components/shared/alternative-feedbacks';
import { Hero } from 'components/shared/alternative-hero';
import Bulletproofs from 'components/shared/bulletproofs';
import { CTA } from 'components/shared/cta';
import { Partners } from 'components/shared/partners';
import { graphql, useStaticQuery } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';
import { docs } from 'utils/urls';

const LoadninjaAlternative = () => {
  const {
    allTestingFeaturesDataJson: { edges: features },
  } = useStaticQuery(graphql`
    query loadninjaFeatures {
      allTestingFeaturesDataJson {
        edges {
          node {
            title
            description
            link
          }
        }
      }
    }
  `);

  return (
    <DefaultLayout>
      <Hero title={'Looking for a LoadNinja alternative?'} />
      <Advantages />
      <AlternativeFeedbacks />
      <Bulletproofs
        title={'Feature-rich and easy-to-use load testing platform'}
        description={'Get everything your team needs to get your performance testing on the right track.'}
        bullets={features.map((node) => ({ ...node }))}
        showLines
        cta={{
          link: 'https://grafana.com/docs/grafana-cloud/k6/?src=k6io',
          text: 'find more in our docs',
        }}
      />
      <Partners
        title={'Trusted and loved by more than 6000 companies for performance testing, including:'}
        onlyCustomers
      />
      <CTA mode="centered" />
    </DefaultLayout>
  );
};

export default LoadninjaAlternative;

export const Head = () => <SEO {...SEO_DATA['loadninja-alternative']} />;
