import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import { TerminalHeader } from 'components/shared/terminal-header';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Arrow from 'svg/arrow.inline.svg';
import { app } from 'utils/urls';

import styles from './alternative-hero.module.scss';

export const Hero = (props) => {
  const { title } = props;
  let { demoLink, description } = props;
  demoLink = demoLink || 'https://go2.grafana.com/k6-contact.html';
  description =
    description ||
    // eslint-disable-next-line max-len
    'Join thousands of engineering teams and enjoy a greater developer experience, flexibility and scalability with k6.';

  const { file: image } = useStaticQuery(graphql`
    query blazemeterHero {
      file(absolutePath: { regex: "/cloud-stub.png/" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <section className={styles.wrapper}>
      <div className={`container ${styles.inner}`}>
        <div className={'row'}>
          <div className={'col-lg-5 col-12'}>
            <Heading className={styles.title} tag={'h1'}>
              {title}
            </Heading>
            <p className={styles.description}>{description}</p>
            <div className={styles.buttonWrapper}>
              <Button
                tag={'a'}
                className={styles.btn}
                cursor
                href={'https://grafana.com/auth/sign-up/create-user?src=k6io'}
              >
                Create free account
              </Button>
              <Arrow className={styles.arrow} />
              <span className={styles.note}>500 VUh/month</span>
            </div>
            <p className={styles.demoLinkWrapper}>
              <a className={'link'} href={demoLink}>
                Schedule a Demo &gt;
              </a>
            </p>
          </div>
        </div>
        <div className={styles.imageBlock}>
          <TerminalHeader theme={'white'} />
          <GatsbyImage image={getImage(image)} className={styles.image} imgStyle={{ objectFit: 'contain' }} />
        </div>
      </div>

      <HeroFrame backgroundLabel={styles.frameWrapper} />
    </section>
  );
};
